import React from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import './_styles.scss';

function TextLimiter(props)
{

    return (
        <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
                <Tooltip className="text-limiter-overlay" id="tooltip-bottom">
                    <div >
                        <p>{props.text}</p>
                    </div>
                </Tooltip>
            }
        >
            <span className="short-text">
                {props.text.substr(0, 50)}
                {props.text.length > 50 ? '...' : null}
            </span>
        </OverlayTrigger>
    );
}

export default TextLimiter;
