import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import './_style.scss';
import {Dropdown} from "react-bootstrap";
import {DropdownWrapper} from "../../atoms/DropdownWrapper/DropdownWrapper";
import {initLogoutAction} from "../../../actions/authActions";
import { history } from '../../../history';
import {updateMilestoneSearchAction} from "../../../actions/milestonesActions";
import {profileImageUrl} from "../../../helpers/urls";
import Sidebar from "../Sidebar/Sidebar";
import {toggleSidebarOffAction, toggleSidebarOn, toggleSidebarOnAction} from "../../../actions/commonActions";
import AddMilestoneButton from "../AddMilestoneButton/AddMilestoneButton";
import AddMilestoneModal from "../AddMilestoneModal/AddMilestoneModal";
import AddButton from "../../atoms/AddButton/AddButtons";
import SidebarItem from "../../molecules/SidebarItem/SidebarItem";
import AddUserModel from "../AddUserModal/AddUserModel";
import EditProfileModal from "../EditProfileModal/EditProfileModal";


const UserImage = () => {
    return (<div className="user__info" data-toggle="dropdown">
        <img className="user__img" src="https://staging.treasuryos.zgrcloud.de/api/images/users/1?time=2020-03-31 15:02:56" alt="" />
    </div>);
}

class PageHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrolled: false,
            searchFocus: false,
            toggleSidebar: false,
            showSearch: true,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', (ev) => this.handleScroll(ev));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', (ev) => this.handleScroll(ev));
    }

    handleScroll(ev) {
        const {scrolled} = this.state;
        if (window.pageYOffset > 20 && !scrolled) {
            this.setState({scrolled: true});
        } else if (window.pageYOffset <= 20 && scrolled) {
            this.setState({scrolled: false});
        }
    }

    searchFocus()
    {
        this.setState({searchFocus: true});
        if (history.location.pathname !== '/overview') {
            history.push('/overview');
        }
    }

    toggleSidebar()
    {
        const { sidebarToggled, toggleSidebarOn, toggleSidebarOff } = this.props;
        if (sidebarToggled) {
            toggleSidebarOff();
        } else {
            toggleSidebarOn();
        }
    }

    toggleSearch () {
        this.setState((prev) => ({showSearch: !prev.showSearch}));
    }

    render() {
        const { scrolled, searchFocus, toggleSidebar, showSearch, showProfileModal } = this.state;
        const { logout, updateSearch, searchString, user, } = this.props;

        return (
            <header className={scrolled ? 'header header--scrolled' : 'header'}>
                <EditProfileModal show={showProfileModal} close={() => this.setState({showProfileModal: false})}/>


                <div className="logo hidden-lg-down">
                    <Link to="/overview">
                        <img className="logo-img" src="/img/ms_logo.png"/>
                    </Link>
                </div>

                    <form className="search">
                        <div className={
                                ('search__inner') +
                                (searchFocus ? ' search--focus' : '')+
                                (showSearch ? '' : ' inactive' )
                        }>
                            <input
                                type="text"
                                className="search__text"
                               placeholder="Search for milestones..."
                               onBlur={() => this.setState({searchFocus: false})}
                               onFocus={() => this.searchFocus()}
                               onChange={(ev) => updateSearch(ev.target.value)}
                               value={searchString}
                            />
                            {/*<i className="zwicon-search search__helper" />*/}
                            {/*<i className="zwicon-arrow-left search__reset" data-sa-action="search-close" />*/}
                        </div>
                    </form>


                    {/*<button className="milestones-add-button" onClick={() => this.toggleSearch()}>*/}
                    {/*    <img src="/img/search.png" className="header-buttons"/>*/}
                    {/*</button>*/}
                    <AddMilestoneButton />
                <div className="user-info-container">
                    <Dropdown>
                        <Dropdown.Toggle as={DropdownWrapper} className="dropdown">
                            <div className="user__info" data-toggle="dropdown">
                                <img className="user__img" src={profileImageUrl(user)} alt="" />
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {
                                user.is_admin === 1
                                    ? <>
                                        <Link to="/settings/users" component={Dropdown.Item}>
                                            Users
                                        </Link>
                                        <Link to="/settings/ventures" component={Dropdown.Item}>
                                            Ventures
                                        </Link>
                                        <Link to="/settings/categories" component={Dropdown.Item}>
                                            Categories
                                        </Link>
                                    </>
                                    : null
                            }
                            <Dropdown.Item onClick={() => this.setState({showProfileModal: true})}>Profile</Dropdown.Item>
                            <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </div>

            </header>
        );
    }
}

const mapStateToProps = state => ({
   searchString: state.milestones.searchString,
   sidebarToggled: state.common.sidebarToggled,
   user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(initLogoutAction()),
    updateSearch: (newValue) => dispatch(updateMilestoneSearchAction(newValue)),
    toggleSidebarOn: () => dispatch(toggleSidebarOnAction()),
    toggleSidebarOff: () => dispatch(toggleSidebarOffAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
