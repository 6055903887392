import React, {Component} from 'react';
import {connect} from 'react-redux';
import StatsCardUser from "../../atoms/StatsCardUser/StatsCardUser";

import {
    quickMilestoneFilterAction,
} from "../../../actions/milestonesActions";
import CategoryOptionLabel from "../../atoms/CategoryOptionLabel/CategoryOptionLabel";

class StatsCard extends Component{
    render() {
        const {milestoneCategory, quickFilter} = this.props;
        const selectedMilestoneCategories = [{value: milestoneCategory.id, label: CategoryOptionLabel(milestoneCategory)}];
        const incompleteTag = {value: 'FILTER_ONLY_INCOMPLETE', label: 'Open'};
        const newTag = {value: 'FILTER_ONLY_NEW', label: 'New'};
        return (
            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xsm-12 stats-card-wrapper">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title" onClick={() => quickFilter([], selectedMilestoneCategories, [], null)}>
                            {milestoneCategory.name}
                        </h4>
                        <div className="stats-center">
                            <div className="main-value">
                                {
                                    milestoneCategory.new_milestones
                                        ?
                                        <div className="sub-value-container opacity0">
                                            <div className="sub-value">
                                                00
                                            </div>
                                            <div className="sub-value-label">

                                            </div>
                                        </div>
                                        : null
                                }
                            <span onClick={() => quickFilter([], selectedMilestoneCategories, [], incompleteTag)}>
                                {milestoneCategory.open_milestones}
                            </span>
                                {
                                    milestoneCategory.new_milestones
                                    ?
                                        <div className="sub-value-container" onClick={() => quickFilter([], selectedMilestoneCategories, [], newTag)}>
                                            <div className="sub-value">
                                                {milestoneCategory.new_milestones < 10 ? '0' : ''}
                                                {milestoneCategory.new_milestones}
                                            </div>
                                            <div className="sub-value-label">
                                                NEW
                                            </div>
                                        </div>
                                    : null
                                }

                            </div>

                            {/*<div className="sub-value" onClick={() => quickFilter([], selectedMilestoneCategories, [], incompleteTag)}>*/}
                            {/*    {milestoneCategory.open_milestones}*/}
                            {/*</div>*/}
                        </div>
                        <div className="users_list">
                            {
                                milestoneCategory.users.map(user =>
                                    <StatsCardUser selectedMilestoneCategories={selectedMilestoneCategories} user={user} />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    quickFilter: (
        selectedAssignedTos,
        selectedMilestoneCategories,
        selectedVentures,
        selectedTag
    ) => dispatch(quickMilestoneFilterAction(selectedAssignedTos,
        selectedMilestoneCategories,
        selectedVentures,
        selectedTag
    )),
});

export default connect(null, mapDispatchToProps)(StatsCard);
