import {ACT_VEN_ADD_201, ACT_VEN_ADD_INIT, ACT_VEN_FETCH_200, ACT_VEN_FETCH_INIT} from "../constants/actions";

const defaultState = {
    isLoadingVentures: false,
    didLoadVentures: false,
    isAddingVenture: false,
    venturesList: [],
};


const venturesReducer = (state = defaultState, action) => {
    console.log('cat reducer state action', state, action);
    switch (action.type) {
        case ACT_VEN_FETCH_INIT:
            return {
                ...state,
                isLoadingVentures: true,
                didLoadVentures: false,
                venturesList: [],
            };
        case ACT_VEN_FETCH_200:
            return {
                ...state,
                isLoadingVentures: false,
                didLoadVentures: true,
                venturesList: action.ventures,
            }
        case ACT_VEN_ADD_INIT:
            return {
                ...state,
                isAddingVenture: true,
            };
        case ACT_VEN_ADD_201:
            let newVenturesList = [action.venture].concat(state.venturesList);
            console.log('cat reducer concat ACT_VEN_ADD_201', {
                ...state,
                isAddingVenture: false,
                venturesList: newVenturesList,
            });
            return {
                isAddingVenture: false,
                venturesList: newVenturesList,
            };

    }
    return state;
};

export default venturesReducer;
