
const monthShortName = (m) => {
    switch (m) {
        case 0:
            return 'Jan.';
        case 1:
            return 'Feb.';
        case 2:
            return 'Mar.';
        case 3:
            return 'Apr.';
        case 4:
            return 'May.';
        case 5:
            return 'Jun.';
        case 6:
            return 'Jul.';
        case 7:
            return 'Aug.';
        case 8:
            return 'Sep.';
        case 9:
            return 'Oct.';
        case 10:
            return 'Nov.';
        case 11:
            return 'Dec.';
    }
}

const strPostfix = (d) => {
    switch (d) {
        case 1:
        case 21:
        case 31:
            return 'st';
        case 2:
        case 22:
            return 'nd';
        case 3:
        case 23:
            return 'rd';
        default:
            return 'th';
    }
}

export const shortDateFormat = (dateStr) => {
    const date = new Date(dateStr);
    const d = date.getDate();
    const m = date.getMonth();
    return `${d}${strPostfix(d)} ${monthShortName(m)}`;
}

export const ligaDateFormat = (dateStr) => {
    const date = new Date(dateStr);
    const d = date.getDate();
    const m = date.getMonth();
    const y = String(date.getFullYear()).substring(2);
    return `${d}-${m+1}-${y}`;
}

export const adjustForTimezone = (date1) => {
    let userDate = new Date(date1);
    const sysDate = new Date();
    const timeOffsetInMS = -1 * sysDate.getTimezoneOffset() * 60000;
    userDate.setTime(userDate.getTime() + timeOffsetInMS);
    return userDate;
}
