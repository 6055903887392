import React, {Component} from 'react';
import StatsCardUser from "../../atoms/StatsCardUser/StatsCardUser";
import {quickMilestoneFilterAction} from "../../../actions/milestonesActions";
import {connect} from "react-redux";
import VentureOptionLabel from "../../atoms/VentureOptionLabel/VentureOptionLabel";

class VentureStatsCard extends Component{
    render() {
        const {venture, quickFilter} = this.props;
        const selectedVentures = [{value: venture.id, label: VentureOptionLabel(venture)}];
        const incompleteTag = {value: 'FILTER_ONLY_INCOMPLETE', label: 'Open'};
        const newTag = {value: 'FILTER_ONLY_NEW', label: 'New'};
        return (
            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xsm-12 venture-card-wrapper">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title" onClick={() => quickFilter([], [], selectedVentures, null)}>
                            {venture.name}
                        </h4>
                        <div className="stats-center">
                            <div className="main-value">
                                <span onClick={() => quickFilter([], [], selectedVentures, incompleteTag)}>
                                    {venture.open_milestones}
                                </span>
                                {
                                    venture.new_milestones
                                        ?
                                        <div className="sub-value-container" onClick={() => quickFilter([], [], selectedVentures, newTag)}>
                                            <div className="sub-value">
                                                {venture.new_milestones < 10 ? '0' : ''}
                                                {venture.new_milestones}
                                            </div>
                                            <div className="sub-value-label">
                                                NEW
                                            </div>
                                        </div>
                                        : null
                                }

                            </div>
                        </div>
                        <div className="users_list">
                            {
                                venture.users.map(user =>
                                    <StatsCardUser selectedVentures={selectedVentures} user={user} />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    quickFilter: (
        selectedAssignedTos,
        selectedMilestoneCategories,
        selectedVentures,
        selectedTag
    ) => dispatch(quickMilestoneFilterAction(selectedAssignedTos,
        selectedMilestoneCategories,
        selectedVentures,
        selectedTag
    )),
});

export default connect(null, mapDispatchToProps)(VentureStatsCard);
